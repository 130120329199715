import crudGetters from "@/store/templates/crud/getters";

export default {
  tag: state => state.model,
  id: state => state.model.id,
  name: state => state.model.name,
  type: state => state.model.type,
  subType: state => state.model.subType,
  isFilterable: state => state.model.isFilterable,
  ...crudGetters
};

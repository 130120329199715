<template>
  <table-overview
    title="Tags"
    store="tags"
    :headers="[
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'Sub type', value: 'subType', sortable: false },
      { text: 'Is Filterable', value: 'isFilterable', sortable: false },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'tags_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <tag-table-row slot="table-row" slot-scope="{ item }" :tag-id="item" />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import tagsModule from "@/store/modules/tags";
import TagTableRow from "../../components/tag/TagTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "tags",
  tagsModule
);

export default {
  name: "tags-view",
  components: {
    TagTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "tags.create" },
        rolesOrPermissions: "tags_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>

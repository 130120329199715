<template>
  <v-card color="white">
    <crud-header :title="name" type="Tag" store="tag" />
    <tag />
  </v-card>
</template>

<script type="text/babel">
import tagModule from "@/store/modules/tag";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import Tag from "../../components/tag/Tag";

const { mapGetters } = createNamespacedHelpers("tag");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "tag",
  tagModule
);

export default {
  name: "tag-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Tag,
    CrudHeader
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["name", "id"])
  },
  ...detailViewLifecycleMethods
};
</script>

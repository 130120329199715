var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Tags","store":"tags","headers":[
    { text: 'Name', value: 'name' },
    { text: 'Type', value: 'type', sortable: false },
    { text: 'Sub type', value: 'subType', sortable: false },
    { text: 'Is Filterable', value: 'isFilterable', sortable: false },
    {
      text: 'Actions',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'tags_view'
    }
  ],"actions":_vm.actions,"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('tag-table-row',{attrs:{"tag-id":item}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      label="Name"
      autocomplete="not-my-name"
      :counter="255"
      :error-messages="errors.collect('name')"
      data-vv-name="name"
      required
    />
    <v-autocomplete-tag-type v-model="type" />
    <v-autocomplete-tag-sub-type
      v-model="subType"
      :type="type"
      :disabled="!typeSelected"
    />
    <v-flex xs2>
      <v-switch v-model="isFilterable" color="primary" label="Is Filterable" />
    </v-flex>
  </v-container>
</template>

<script type="text/babel">
import { isNil } from "lodash";
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteTagType from "@/components/tag-type/VAutocompleteTagType";
import VAutocompleteTagSubType from "@/components/tag-sub-type/VAutocompleteTagSubType";
const { mapComputed } = createNamespacedHelpers("tag");

export default {
  name: "tag",
  components: { VAutocompleteTagSubType, VAutocompleteTagType },
  inject: ["$validator"],
  data: () => {
    return {
      tagTypes: ["article", "keyword", "website"]
    };
  },
  computed: {
    ...mapComputed(["name", "type", "subType", "isFilterable"]),
    typeSelected() {
      return !isNil(this.type);
    }
  }
};
</script>

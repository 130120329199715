<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Tag"
      store="tag"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'tags.show',
            params: { tagId: id }
          })
      "
    />
    <tag />
  </v-card>
</template>

<script type="text/babel">
import tagModule from "@/store/modules/tag";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "../helpers";
import Tag from "../../components/tag/Tag";

const { mapGetters } = createNamespacedHelpers("tag");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "tag",
  tagModule
);

export default {
  name: "tag-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Tag,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name", "type", "color"])
  },
  ...createViewLifecycleMethods
};
</script>

import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  name: null,
  type: null,
  subType: null,
  isFilterable: true
});

export default () => ({
  ...initialCrudState(initialModel)
});

<template>
  <tr>
    <td>
      <v-icon :style="`color: ${tag.color}`" class="mr-3">
        {{ tag.color ? "brightness_1" : "panorama_fish_eye" }}
      </v-icon>
      {{ tag.name }}
    </td>
    <td>{{ typeName }}</td>
    <td>{{ subTypeName }}</td>
    <td>{{ tag.isFilterable ? "Yes" : "No" }}</td>
    <td>
      <v-btn icon :to="{ name: 'tags.show', params: { tagId: tag.id } }">
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { isNil } from "lodash";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapTagGetters,
  mapState: mapTagState
} = createNamespacedHelpers("tags");

export default {
  name: "tag-table-row",
  props: {
    tagId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapTagGetters([FIND_BY_ID]),
    ...mapTagState(["tagType", "tagSubType"]),
    tag() {
      return this[FIND_BY_ID](this.tagId);
    },
    typeName() {
      return !isNil(this.tag.type) ? this.tagType[this.tag.type].name : null;
    },
    subTypeName() {
      return !isNil(this.tag.subType)
        ? this.tagSubType[this.tag.subType].name
        : null;
    }
  }
};
</script>
